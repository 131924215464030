@import "../../../../../node_modules/foundation-sites/scss/foundation.scss";
@import "../../toyota-style-standards.scss";

.video-container {
    width: 100%;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    row-gap: 24px;

    .video-header {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        align-self: stretch;
        font: $font32-bold;
        height: auto;

        @include breakpoint (medium only){
            font: $h3-bold;
        }

        @include breakpoint (small only){
            font: $h12-bold;
        }

        .video-sub-header {
            display: flex;
            justify-content: space-between;
            height: auto;
            width: 100%;
            align-content: center;

            .video-description-container {
                font: $h12-reg;
                max-width: 758px;

                @include breakpoint (medium down){
                    font: $h4-reg;
                }
            }

            @include breakpoint(medium down) {
                flex-direction: column;
                row-gap: 24px;
            }

            .video-button-container {
                display: flex;
                max-height: 56px;
                padding: 16px 32px;
                justify-content: center;
                align-items: center;
                gap: 8px;
                border-radius: 48px;
                background: #000;
                color: #FFF;
                font: $p14-semibold;
                white-space: nowrap;
                width: max-content;

                &:hover {
                    text-decoration: none;
                }

                @include breakpoint(small down) {
                    max-width: fit-content;
                    padding: 8px 20px;
                    font: $p11-semibold;
                }
            }
        }
    }

    .video-content {
        width: 100%;

        img{
            height: auto;
            object-fit: cover;
        }
    }
}