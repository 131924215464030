@import "../../../../../node_modules/foundation-sites/scss/foundation.scss";
@import "../../toyota-style-standards.scss";

.release-notes-tile {
    display: flex;
    background-color: #F8F8F8;
    border-radius: 12px;
    width: 100%;
    flex-direction: column;
    height: 100%;

    .image-container {
        position: relative;
        width: 100%;

        img {
            width: 100%;
            border-radius: 12px 12px 0px 0px;
            object-fit: cover;
        }

        .overlay {
            display: none;
            position: absolute;
            inset: 0;
            background-color: rgba(0, 0, 0, 0.5);
            align-items: center;
            justify-content: center;

            @include breakpoint(medium down) {
                display: flex;
                background: linear-gradient(0deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.00) 93.9%);
                align-items: flex-end;
                padding-bottom: 24px;
            }
        }
    }

    .text-container {
        display: flex;
        flex-direction: column;
        padding: 32px;

        @include breakpoint(medium only) {
            padding: 24px;
        }

        .date {
            font: $h4-reg;
            margin-bottom: 8px;

            @include breakpoint(medium only) {
                margin-bottom: 4px;
            }
        }

        .title {
            font: $h3-bold;
            margin-bottom: 16px;

            @include breakpoint(medium down) {
                font: $h12-bold;
                margin-bottom: 4px;
            }
        }

        .description {
            font: $h4-reg;

            p {
                margin: 0;
            }
        }
    }

    &.spotlight {
        flex-direction: row;

        @include breakpoint(1300px down) {
            border-radius: 12px;
            flex-direction: column;
        }

        .image-container {
            img {
                border-radius: 12px 0px 0px 12px;
                height: 100%;
                width: 100%;
                object-fit: cover;

                @include breakpoint(1300px down) {
                    border-radius: 12px 12px 0 0;
                }
            }
        }

        .text-container {
            padding: 24px 36px 20px 36px;
            width: 100%;

            @include breakpoint(small only) {
                padding: 32px;
            }
        }
    }

}

.release-notes-tile:hover {
    .overlay {
        display: flex;
        border-radius: 12px 0px 0px 12px;

        @include breakpoint(1300px down) {
            border-radius: 12px 12px 0 0;
        }
    }
}