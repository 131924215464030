/**
 * Foundation for Sites
 * Version 6.7.5
 * https://get.foundation
 * Licensed under MIT Open Source
 */
/*
    TOYOTA PRIMARY COLOR PALATTE
*/
/*
    TOYOTA ACCENT COLOR PALATTE
*/
/*
    TOYOTA STANDARD FONT TYPES
    README:
    1. Make sure you don't add .svg files to font definitions. We use svg images in some places
       and using .svg fonts requires special configuration to notify browsers
       not to treat the file as an image
    2. Each @font-face rule specifies two file types. This is because we want to support the font
       for all browsers - IE, Chrome and Edge
*/
@font-face {
  font-family: "ToyotaType-Regular";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Regular.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Light";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Light.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Bold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Bold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Semibold";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Semibold.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Black";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Black.woff") format("woff"); }

@font-face {
  font-family: "ToyotaType-Book";
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.ttf") format("truetype");
  src: url("https://engage.toyota.com/static/fonts/ToyotaType-Book.woff") format("woff"); }

/*
    PRINT CONFIGUTARIONS
*/
@page {
  size: portrait;
  margin: 33px; }

/*
    VARIABLE FONT DEFINITIONS
*/
/*
    BREAKPOINT VARIABLE DEFINITIONS
*/
/* 
Style Standardization Wish List

- Get rid of all "font-weight" references in SCSS files and replace with appropriate weight Toyota font definition
- Get rid of all "font-size" references in SCSS files and replace with appropriate variable definition

*/
/*
    CSS ANIMATION & TRANSITIONS
*/
.footer {
  background-color: #E6E6E6;
  bottom: 0;
  margin-top: 30px;
  padding: 40px 24px 30px; }
  @media print {
    .footer {
      display: none !important; } }
  @media print, screen and (min-width: 40em) {
    .footer {
      padding: 40px 50px 30px; } }
  .footer .title {
    color: #58595B;
    font: 22px "ToyotaType-Semibold", sans-serif;
    margin-bottom: 12px; }
  .footer .subtitle {
    color: #252525;
    font-size: 18px;
    margin: 0px 0px 0px 20px;
    bottom: 0; }
    .footer .subtitle br {
      height: 26px; }
  .footer .list {
    list-style-type: none;
    margin: 0px; }
  .footer .spacer {
    font-weight: bold;
    color: #808080; }
  .footer .link {
    color: #58595B;
    font-family: "ToyotaType-Light", sans-serif;
    font-size: 14px;
    width: fit-content;
    cursor: pointer; }
    .footer .link:hover {
      text-decoration: underline; }
  .footer a div, .footer span {
    pointer-events: none; }
  .footer .footer-link-list {
    display: inline-block;
    padding-left: 15px;
    top: 0px; }
  .footer .columnBorder {
    border-bottom: 1px solid #808080; }

.footer-header {
  font: 14px "ToyotaType-Semibold", sans-serif;
  padding-bottom: 12px; }
  .footer-header a {
    display: flex;
    align-items: center;
    column-gap: 5px;
    color: #ca1f00; }
    .footer-header a span {
      padding-bottom: 2px; }
    .footer-header a .privacy-icon {
      max-width: 40px;
      max-height: 20px; }
  .footer-header a:hover {
    color: #ca1f00; }
  @media screen and (min-width: 84.375em) {
    .footer-header {
      margin: auto;
      width: 1254px; } }

.footer-sections {
  display: flex;
  flex-direction: column; }
  @media print, screen and (min-width: 40em) {
    .footer-sections {
      flex-flow: row wrap; } }
  @media print, screen and (min-width: 64em) {
    .footer-sections {
      flex-flow: row wrap; } }
  @media screen and (min-width: 84.375em) {
    .footer-sections {
      margin: auto;
      width: 1254px; } }

.engage-resources-section {
  order: 1;
  padding-bottom: 40px; }
  @media print, screen and (min-width: 40em) {
    .engage-resources-section {
      flex: 0 0 calc(100% / 2); } }
  @media print, screen and (min-width: 64em) {
    .engage-resources-section {
      flex: 0 0 calc(100% / 3); } }

.external-resources-section {
  order: 2;
  padding-bottom: 40px; }
  @media print, screen and (min-width: 40em) {
    .external-resources-section {
      flex: 0 0 calc(100% / 2);
      order: 3; } }
  @media print, screen and (min-width: 64em) {
    .external-resources-section {
      flex: 0 0 calc(100% / 3);
      order: 2; } }

.certification-section {
  display: flex;
  flex-direction: column;
  order: 3;
  padding-bottom: 40px; }
  @media print, screen and (min-width: 40em) {
    .certification-section {
      flex: 0 0 calc(100% / 2);
      order: 2; } }
  @media print, screen and (min-width: 64em) {
    .certification-section {
      flex: 0 0 calc(100% / 3);
      order: 3; } }

.engage-app {
  order: 4;
  padding-bottom: 40px; }
  @media print, screen and (min-width: 40em) {
    .engage-app {
      align-self: flex-end;
      flex: 0 0 calc(100% / 2); } }
  @media print, screen and (min-width: 64em) {
    .engage-app {
      flex: 0 0 calc(100% / 3);
      order: 5; } }

.cta {
  color: #252525;
  font: 20px "ToyotaType-Semibold", sans-serif;
  margin-bottom: 10px;
  text-transform: uppercase; }

.app-store-link,
.google-play-link {
  height: 48px;
  margin-right: 8px; }

.terms-and-conditions {
  order: 5;
  padding-bottom: 40px; }
  @media print, screen and (max-width: 39.99875em) {
    .terms-and-conditions {
      padding: 0 24px; } }
  @media print, screen and (min-width: 40em) {
    .terms-and-conditions {
      flex: 0 0 100%; } }
  @media print, screen and (min-width: 64em) {
    .terms-and-conditions {
      align-self: flex-end;
      flex: 0 0 calc(100% * 2 / 3);
      order: 4; } }

.terms {
  color: #58595B;
  font: 14px "ToyotaType-Semibold", sans-serif;
  padding-bottom: 6px;
  display: flex;
  column-gap: 20px; }

.terms a {
  color: #58595B; }

.copyright {
  color: #58595B;
  font: 12px "ToyotaType-Light", sans-serif; }

.hybrid-app-buffer {
  height: 200px;
  visibility: hidden; }
