@import "../../../../../node_modules/foundation-sites/scss/foundation.scss";
@import "../../toyota-style-standards.scss";

.resources-tile {
  flex-shrink: 0;
  width: 400px;
  position: relative;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;

  @include breakpoint(medium down) {
    width: 320px;
  }

  .resources-image-container {
    position: relative;
    margin-bottom: 16px;
    max-width: 400px;

    @include breakpoint(medium down) {
      max-width: 320px;
    }

    .image {
      border-radius: 6px;
    }

    .resources-tile-viewing-options {
      position: absolute;
      border-top-right-radius: 6px;
      background-color: $dark-black;
      top: 0%;
      right: 0%;
      z-index: 2;
      height: 36px;
      display: flex;
      padding: 13px 8px;
      gap: 10px;
  
      .media-icon {
        max-width: 22px;
        align-self: center;
      }
    }

    .smartpath-button-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;
      position: absolute;
      bottom: 50%;
      transform: translateY(50%);
      z-index: 2;
      width: 100%;
    
      @include breakpoint(medium down) {
        bottom: 32px;
        transform: none;
      }
    }

    .resources-arrow {
      cursor: pointer;
      position: absolute;
      z-index: 2;
      top: 89%;
      left: 86%;
      transform-origin: center;
      transform: rotate(360deg);
      transition: transform 0.3s ease-in-out, stroke 0.4s ease-in-out;
    }

    .smartpath-overlay-container {
      background-color: $primary-black;
      border-radius: 6px;
      z-index: 1;
      width: 100%;
      height: 100%;
      position: absolute;
      opacity: 0.6;
      filter: brightness(50%);
    }
  }

  .resources-tile-title {
    font: $h3-bold;
  }

  .resources-tile-description {
    font: $h4-book;
    margin-bottom: 16px;
  }
}

.resources-tile:hover {
  .resources-arrow {
    transform: rotate(315deg);

    .arrow {
      fill: $primary-red;
    }
  }
}
