@import "../../../../../node_modules/foundation-sites/scss/foundation.scss";
@import "../../toyota-style-standards.scss";

.resource-tabs-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 120px;
  width: 100%;

  @include breakpoint(medium only) {
    margin-top: 64px;
  }

  @include breakpoint(small only) {
    margin-top: 30px;
  }

  .resources-tabs-container {
    display: flex;
    gap: 48px;
    background-color: #F8F8F8;
    border-radius: 12px;
    width: fit-content;
    overflow-x: scroll;
    scrollbar-width: none;
  
    @include breakpoint(medium only) {
      gap: 32px;
    }
  
    @include breakpoint(small only) {
      gap: 8px;
      height: 66px;
      max-width: 380px;
    }
  
    .resource-tab {
      display: flex;
      align-items: center;
      padding: 16px 24px;
      font: $h3-reg;
      border-radius: 12px;
      width: fit-content;
      white-space: nowrap;
      cursor: pointer;
  
      @include breakpoint(medium only) {
        font: $h12-reg;
        text-align: center;
      }
  
      @include breakpoint(small only) {
        font: $h4-reg;
        text-align: left;
        white-space: wrap;
      }
  
      &.selected {
        background-color: $dark-black;
        color: $primary-white;
        font: $h3-bold;

        @include breakpoint(medium only) {
          font: $h12-bold;
        }
    
        @include breakpoint(small only) {
          font: $h4-bold;
        }
      }
    }
  }

  .resources-tabs-container::-webkit-scrollbar {
    display: none;
  }
}
