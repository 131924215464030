@import "../../../../../node_modules/foundation-sites/scss/foundation.scss";
@import "../../toyota-style-standards.scss";

.search {
  margin-top: 57px;
  position: relative;

  .search-icon {
    position: absolute;
    top: 15px;
    left: 31px;
  }

  input {
    padding: 15px 48px 14px 75px;
    height: 60px;
    width: 100%;
    border-radius: 30px;
    border: 1px solid #969696B2;
    font: $p14-reg;
    outline: none;
    margin: 0;

    &::placeholder {
      color: #00000033;
    }
  }

  .close-icon {
    position: absolute;
    top: 23px;
    right: 25px;
    width: 16px;
    height: 16px;
    cursor: pointer;
  }

  @include breakpoint(medium only) {
    margin-top: 48px;

    .search-icon {
      top: 14px;
      left: 24px;
    }

    input {
      padding: 17px 48px 17px 68px;
    }
  }

  @include breakpoint(small only) {
    margin-top: 18px;

    .search-icon {
      top: 15px;
      left: 24px;
      width: 24px;
      height: 24px;
    }

    input {
      padding: 12px 48px 12px 58px;
      height: 54px;
    }

    .close-icon {
      top: 19px;
      right: 24px;
      width: 12px;
      height: 12px;
    }
  }
}