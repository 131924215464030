@import "../../../../../node_modules/foundation-sites/scss/foundation.scss";
@import "../../toyota-style-standards.scss";

.video-modal-close-icon {
    display: flex;
    width: 38px;
    height: 38px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 20px;
    background: #000;
    fill: #FFF;

    @include breakpoint(small down) {
        width: 24px;
        height: 24px;
        padding: 5px;
    }

    &:hover {
        background: #222222;
        transform: scale(1.05);
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    }
}

.react-responsive-modal-modal.video-modal {
    max-width: 1430px;
    background-color: #f0f0f0;
    padding: 32px 32px 0 32px;
    border-radius: 16px;
    width: 100%;
    height: 100%;
    max-height: 850px;
    display: flex;
    justify-content: center;
    align-items: center;

    &.noCollection {
        max-width: 1144px;
        padding: 32px;
        height: unset;
        max-height: unset;
    }

    @include breakpoint(medium down) {
        height: unset;
        max-height: unset;
        padding: 32px;
    }

    @include breakpoint(large up) {
        height: max-content;
    }

    @include breakpoint(small down) {
        max-width: 404px;
        padding: 16px;
    }

    .video-container {
        width: 100%;
        display: flex;
        height: auto;
        flex-direction: column;
        row-gap: 24px;

        @include breakpoint(medium down) {
            row-gap: 24px;
        }

        @include breakpoint(small down) {
            row-gap: 16px;
        }

        .modal-title {
            font: $h6-bold;

            @include breakpoint(medium down) {
                font: $h3-bold;
            }

            @include breakpoint(small down) {
                font: $p5-bold;
            }
        }

        .video-section {
            width: 100%;
            display: flex;
            flex-direction: row;
            column-gap: 16px;
            border-radius: 6px;

            &.noCollection {
                max-height: unset;
            }

            @include breakpoint(medium down) {
                flex-direction: column;
                row-gap: 48px;
            }

            @include breakpoint(small down) {
                flex-direction: column;
                justify-content: unset;
                row-gap: 36px;
            }

            .video-main-video-container {
                width: 100%;
                display: flex;
                flex-direction: column;
                row-gap: 32px;
                @include breakpoint(medium down) {
                    row-gap: 24px;
                }
    
                @include breakpoint(small down) {
                    row-gap: 16px;
                }

                .video-main-video {
                    max-width: 1080px;
                    width: 100%;
                    border-radius: 6px;
                    position: relative;
                    aspect-ratio: 16/9;

                    .video-main-video-overlay {
                        display: flex;
                        width: 270px;
                        height: 32px;
                        align-items: center;
                        position: absolute;
                        column-gap: 16px;
                        bottom: 10px;
                        left: 15px;
                        color: #ffffff;
                        z-index: 1;
                        font: $p11-bold;
                    }

                    .video-js {
                        border-radius: 6px;
                    }

                    .video-js .vjs-tech {
                        border-radius: 6px;
                    }

                    .vjs-container {
                        border-radius: 6px;

                        .vjs-poster {
                            border-radius: 6px;

                            img {
                                border-radius: 6px;
                            }
                        }
                    }
                }

                .video-description-container {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    row-gap: 8px;

                    padding-bottom: 32px;
                    @include breakpoint(medium down) {
                        padding-bottom: unset;
                    }

                    .video-title{
                        width: 100%;
                        font: $h6-bold;
                        text-wrap: balance;

                        @include breakpoint(medium down) {
                            font: $h3-bold;
                        }
            
                        @include breakpoint(small down) {
                            font: $h7-bold;
                        }
                    }

                    .video-description {
                        width: 100%;
                    }
                }
            }

            .video-collection-section {
                max-width: 260px;
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                row-gap: 12px;
                aspect-ratio: 16/9;

                @include breakpoint(medium down) {
                    max-width: unset;
                    aspect-ratio: unset;
                }

                .video-collection-title {
                    font: $h12-semibold;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    word-break: break-word;
                    white-space: nowrap;

                    @include breakpoint(small down) {
                        font: $h4-semibold;
                    }
                }

                .video-collection-list {
                    height: 100%;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    row-gap: 12px;
                    overflow-y: scroll;
                    overflow-x: hidden;

                    @include breakpoint(medium down) {
                        flex-direction: row;
                        overflow-x: scroll;
                        overflow-y: hidden;
                    }

                    &::-webkit-scrollbar-thumb {
                        border-radius: 8px;
                        background: #D3D3D3;
                        max-height: 354px;
                    }

                    &::-webkit-scrollbar {
                        width: 6px;

                        @include breakpoint(medium down) {
                            display: none;
                        }
                    }

                    .video-item {
                        cursor: pointer;
                        position: relative;

                        @include breakpoint(medium down) {
                            display: flex;
                            flex-direction: column;
                            row-gap: 8px;
                            min-width: 260px;
                            @include breakpoint(small down) {
                                min-width: 200px;
                            }
                        }

                        .video-modal-thumbnail {
                            position: relative;
                            width: 250px;
                            border-radius: 8px;
                            max-height: 140px;
                            @include breakpoint(small down) {
                                max-height: 109px;
                                width: 190px;
                            }

                            &.has-border::before {
                                content: '';
                                position: absolute;
                                top: 0;
                                left: 0;
                                right: 0;
                                bottom: 0;
                                border: 2px solid #EB0A1E;
                                border-radius: 8px;
                                pointer-events: none;
                                z-index: 1;
                            }

                            img {
                                width: 254px;
                                height: 140px;
                                @include breakpoint(small down) {
                                    width: 190px;
                                    height: 109px;
                                }
                                border-radius: 8px;
                                -webkit-user-drag: none;
                                user-select: none;
                            }

                            .video-modal-selected-text {
                                color: #FFF;
                                font: $p7-bold;
                                position: absolute;
                                z-index: 1000;
                                left: 84px;
                                top: 61px;
                                @include breakpoint(small down) {
                                    left: 53px;
                                    top: 47px;
                                }
                            }
                        }

                        .video-modal-description {
                            width: 100%;
                            justify-content: space-between;
                            display: flex;
                            flex-direction: row;
                            max-width: 254px;

                            @include breakpoint(small down) {
                                max-width: 190px;
                            }

                            .video-modal-title {
                                font: $p7-reg;
                                word-wrap: break-word;
                                padding: 0 5px 0 0;
                                overflow-wrap: break-word;
                                text-wrap: balance;

                                &.bolded {
                                    font: $p7-bold;
                                }
                            }

                            .video-modal-video-length {
                                font: $p12-reg;
                                color: #666;
                                padding-top: 2px;
                            }
                        }
                    }
                }
            }
        }
    }
}