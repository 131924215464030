@import "../../../../../node_modules/foundation-sites/scss/foundation.scss";
@import "../../toyota-style-standards.scss";

.smartpath-release-notes-container {
    width: 100%;
    max-width: 1560px;
    padding: 0px 60px;
    margin: auto;

    @include breakpoint(small only) {
        padding: 0px 24px;
    }
}