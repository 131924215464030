@import "../../../../../node_modules/foundation-sites/scss/foundation.scss";
@import "../../toyota-style-standards.scss";

.logo-showcase-component {
  .logo-showcase-header {
    margin-bottom: 24px;
    font: $h13-bold;
  
    @include breakpoint(medium down) {
      font: $h3-bold;
    }
  
    @include breakpoint(small only) {
      margin-bottom: 14px;
      font: $p1-bold;
    }
  }

  .logo-container-group {
    display: flex;
    flex-direction: column;
    gap: 32px;

    .logo-container {
      display: flex;
      gap: 50px;
    
      @include breakpoint(medium down) {
        flex-direction: column;
        gap: 24px;
      }
    
      .text-section {
        width: 235px;
  
        @include breakpoint(medium down) {
          width: 100%;
        }
  
        .group-title {
          margin-bottom: 8px;
          font: $h7-bold;
    
          @include breakpoint(small only) {
            font: $h4-bold;
          }
        }

        .group-description {
          font: $h4-reg;
        }
      }
    
      .image-section {
        display: flex;
        flex-wrap: wrap;
        gap: 48px;
        flex: 1;
        background-color: #F8F8F8;
        border-radius: 12px;
        padding: 32px;

        @include breakpoint(medium only) {
          gap: 12px;
        }
    
        @include breakpoint(small only) {
          padding: 16px;
          gap: 12px;
        }

        .logo-image-wrapper {
          display: flex;
          width: 100%;
          max-width: 168px;

          @include breakpoint(medium only) {
            max-width: 120px;
            padding: 10px;
          }
    
          @include breakpoint(small only) {
            max-width: 108px;
            padding: 5px;
          }

          .image-item {
            margin: auto;
          }
        }
      }
    }
  }
}
