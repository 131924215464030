@import "../../../../../node_modules/foundation-sites/scss/foundation.scss";
@import "../../toyota-style-standards.scss";

.smartpath-button {
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    color: $primary-white;
    cursor: pointer;
    font: $h4-semibold;
    width: 325px;
    height: 45px;
    border: 1px solid $primary-white;
    border-radius: 25px;
    padding-bottom: 3px;
    margin: 0px 30px;

    @include breakpoint(medium down) {
      width: 272px;
      margin: 0px 24px;
    }
  }

  .smartpath-button:hover {
    background-color: $primary-red;
    border: none;
  }