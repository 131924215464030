@import "../../../../../node_modules/foundation-sites/scss/foundation.scss";
@import "../../toyota-style-standards.scss";

.smartpath-support-container {
    display: flex;
    justify-content: center;
    padding: 0px 60px;
    margin-top: 150px;

    @include breakpoint(medium only) {
        margin-top: 84px;
    }

    @include breakpoint(small only) {
        padding: 0px 22px 0px 26px;
        margin-top: 48px;
    }

    .smartpath-support-content-holder {
        display: flex;
        gap: 64px;
        border-radius: 8px;
        background: linear-gradient(83deg, #1128AB 0%, #17E0C9 119.55%);
        justify-content: center;
        align-items: center;
        width: 100%;
        min-height: 469px;
        max-width: 1440px;
        color: $primary-white;
        padding: 75px;
        flex-direction: column;

        @include breakpoint(medium only) {
            flex-direction: column;
            align-items: flex-start;
            max-width: 904px;
            padding: 64px 64px 78px;
            min-height: 651px;
            height: 100%;
        }

        @include breakpoint(small only) {
            flex-direction: column;
            max-width: none;
            gap: 32px;
            min-height: 469px;
            padding: 32px;
            height: 100%;
        }

        .content-section {
            display: flex;
            flex-direction: row;
            column-gap: 64px;
            
            @include breakpoint(medium down) {
                flex-direction: column;
                row-gap: 64px;
            }

            .header-section {
                width: 100%;
                max-width: 613px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                gap: 24px;

                @include breakpoint(medium only) {
                    max-width: 776px;
                }

                .header {
                    font: $h11-bold;

                    @include breakpoint(medium only) {
                        font: $h1-bold;
                    }

                    @include breakpoint(small only) {
                        font: $h3-bold;
                    }


                }

                .video-content {
                    width: 100%;

                    .video-js .vjs-big-play-button {
                        @include breakpoint(small down) {
                            height: 1.63332em;
                            width: 3em;
                        }
                    }
                }
            }

            .support-services {
                display: flex;
                gap: 48px;
                flex-direction: column;
                font: $h3-bold;
                width: 100%;
                max-width: 613px;


                @include breakpoint(medium only) {
                    max-width: 776px;
                }

                .service-title {
                    display: flex;
                    gap: 24px;
                    align-items: center;
                }
            }

            .subtitle {
                font: $h12-reg;
                margin-top: 16px;

                @include breakpoint(small only) {
                    font: $h4-reg;
                }
            }

            .header-subtitle {
                font: $h12-reg;

                @include breakpoint(small only) {
                    font: $h4-reg;
                }
            }
        }
        .video-content {
            width: 100%;
        }
    }
}