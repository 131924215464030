@import "../../../../../node_modules/foundation-sites/scss/foundation.scss";
@import "../../toyota-style-standards.scss";

.smartpath-digital-ecosystem-wrapper {
    padding: 0px 55px;
    max-width: 1550px;
    margin: auto;

    @include breakpoint(small only) {
        padding: 0px 24px;
    }

    .digital-ecosystem-container {
        display: flex;
        flex-direction: row;
        align-items: center;

        @include breakpoint(1275px down) {
            flex-direction: column;
            align-items: flex-start;
        }

        #ecosystem-graphic-container {
            width: 100%;
            position: relative;
        
            .dynamic-sections > * {
                position: absolute;
                top: 0px;
                width: 100%;
            }
        }

        .digital-ecosystem-title{
            width: 100%;
            font: $h11-bold;
            margin-bottom: 56px;
            margin-top: 100px;
        
            @include breakpoint (1275px down){
                max-width: 475px;
                font: $h1-bold;
                margin-bottom: 0px;
                margin-top: 84px;
            }
        
            @include breakpoint (small only){
                font: $h3-bold;
                margin-bottom: 0px;
                margin-top: 32px;
            }
        }

        .digital-ecosystem-left-container {
            width: 100%;
            max-width: 589px;
        }

        .accordionV2 {
            margin-bottom: 150px;

            @include breakpoint(1275px down) {
                margin-bottom: 64px;
            }

            @include breakpoint(small only) {
                margin-bottom: 32px;
            }

            .accordion-rows {
                :first-child {
                    border-top: none;
                }
    
                .roadmap-steps {
                    display: flex;
                    flex-direction: column;
                    gap: 15px;
                
                    @include breakpoint(medium only) {
                        display: grid;
                        grid-template-columns: repeat(3, 1fr); 
                        gap: 10px;
                    }
                
                    .roadmap-step {
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                
                        .roadmap-step-subtitle-container {
                            display: flex;
                            align-items: center;
                            gap: 8px;
                
                            .roadmap-step-icon {
                                width: 24px;
                                height: 24px;
                                border-radius: 50%;
                                color: white;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                font: $p11-reg;
                                font-weight: 600;
                                padding-bottom: 2px;
                            }
                
                            .roadmap-step-subtitle {
                                color: #333333;
                                font:$h12-bold;
                                padding-bottom: 2px;
                        
                                @include breakpoint(small only) {
                                    font: $h4-bold;
                                }
                            }
                        }
                
                        .roadmap-step-content {
                            font: $h2-reg;
            
                            @include breakpoint (medium only){
                                font: $h4-reg;
                            }
            
                            @include breakpoint(small only) {
                                font: $h4-reg;
                            }
                        }
                    }
                }
            }
        }
    }
}