@import "../../../../../node_modules/foundation-sites/scss/foundation.scss";
@import "../../toyota-style-standards.scss";

@mixin preflight-checklist-item($background-color, $font-color, $pipe-color ) {
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    background-color: $background-color;
    width: 100%;
    padding: 16px 14px 34px 27px;
    color: $font-color;
    position: relative;

    @include breakpoint (medium only){
        padding: 16px 16px 34px 27px;
    }

    @include breakpoint (small only){
        padding: 16px 16px 24px 24px;
    }

    .item-label {
        align-self: flex-end;
        background-color: $primary-white;
        border: 1px solid $navigation-text;
        color: $navigation-text;
        border-radius: 20px;
        font: $p12-reg;
        padding: 4px 12px;
    }

    .item-title {
        font: $h12-semibold;
    }

    .item-description {
        font: $p5-reg;
    }

    &::after {
        position: absolute;
        z-index: 10;
        bottom: -20px;
        left: 50%;
        content: "";
        height: 20px;
        width: 1px;
        background-color: $pipe-color;
    }

    &:last-child::after {
        display: none;
    }
}

.preflight-checklist-container {
    $default-background-color: #F8F8F8;
    $custom-background-color: var(--background-color);
    display: flex;
    gap: 24px;

    @include breakpoint (medium down){
        flex-direction: column;
    }

    img {
        width: 50%;
        height: auto;
        object-fit: cover;

        @include breakpoint (medium down){
            width: 100%;
        }
    }

    .preflight-checklist-list {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 50%;

        @include breakpoint (medium down){
            width: 100%;
        }

        .preflight-checklist-item {
            @include preflight-checklist-item($default-background-color, $dark-black, $custom-background-color);
        }

        .preflight-checklist-item-completed {
            @include preflight-checklist-item($custom-background-color, $primary-white, $custom-background-color);
        }
    }

}