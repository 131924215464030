@import './toyota-style-standards.scss';
@import 'base';

.kv-playback-widget:not(:last-child),
.kv-metadata,
.kv-spacer {
    display: none;
}

.video-loader {
    width: 100%;
    aspect-ratio: 16/9;
    justify-content: center;
    display: flex;
    align-items: center;
}