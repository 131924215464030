$play-button-path: '../../../resources/images/play-button.svg';

.vjs-control-bar {
  z-index: 20; // playlist-overlay is 10
}

.vjs-poster img {
  object-fit: cover; // in case the poster size doesn't match
}

.video-js .vjs-big-play-button {
  background: url('#{$play-button-path}') no-repeat center;
  background-size: contain;
  border: none;
  width: 80px;
  height: 80px;
  border-radius: 100%;
}

.vjs-icon-placeholder {
  display: none;
}