@import "../../../../../node_modules/foundation-sites/scss/foundation.scss";
@import "../../toyota-style-standards.scss";

.sales-tool-tile-container {
    max-width: 320px;
    min-height: 326px;
    min-width: 320px;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: #F8F8F8;
    border-radius: 8px;

    .image-container {
        max-height: 180px;
        width: 100%;
        height: auto;
        position: relative;

        img {
            max-height: 180px;
            object-fit: contain;
            width: 100%;
            height: 100%;
            -webkit-user-drag: none;
            user-select: none;
        }

        .tool-badge-container {
            max-width: 200px;
            padding: 4px 12px;
            align-items: center;
            position: absolute;
            left: 20px;
            top: 140px;
            z-index: 1;
            border-radius: 20px;
            border: 1px solid #333;
            background: #FFF;
            font: $p12-reg;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-word;
            white-space: nowrap;
        }
    }

    .text-container {
        display: flex;
        padding: 24px;
        flex-direction: column;
        row-gap: 8px;
        align-self: stretch;
        height: auto;

        .text-title {
            font: $h2-bold;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-word;
            white-space: nowrap;
        }

        .text-description {
            font: $h4-reg;
            line-height: 22px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            max-height: 67px;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-word;
            white-space: normal;
        }
    }
}