@import "../../../../../node_modules/foundation-sites/scss/foundation.scss";
@import "../../toyota-style-standards.scss";

.results-container {
  width: 100%;
  padding: 0px 55px;
  max-width: 1550px;
  margin: 0px auto 150px auto;

  @include breakpoint(medium only) {
    margin: 0px auto 64px auto;
  }

  @include breakpoint(small only) {
    padding: 0px 24px;
    margin: 0px auto 32px auto;
  }

  .results-header {
    font: $h11-bold;
    margin-bottom: 64px;

    @include breakpoint(medium only) {
      font: $h1-bold;
      margin-bottom: 48px;
    }

    @include breakpoint(small only) {
      font: $h3-bold;
      margin-bottom: 24px;
    }
  }

  .results-tile-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 15px;
    justify-content: center;

    @include breakpoint(medium only) {
      gap: 12px;
    }

    @include breakpoint(small only) {
      flex-direction: column;
      gap: 12px;
    }
  }
}


  .parent-tile {
    position: relative;
    color: #000000;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    width: 100%; 
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #F8F8F8;
    min-height: 295px;
    justify-content: space-between;
    padding: 40px 40px 26px 40px;

    @include breakpoint(medium only) {
      padding: 24px;
    }

    @include breakpoint(small only) {
      padding: 24px;
      min-height: unset;
    }

    &.expanded {
      color: #ffffff;
    }

    .tile-label {
      position: absolute;
      top: 0px;
      right: 0px;
      padding: 5px 32px 8px 32px;
      font: $h12-semibold;
      color: #fff;
      border-radius: 0px 12px;

      @include breakpoint(medium down) {
        padding: 5px 16px 8px 16px;
      }
    }

    .top-section {
      .parent-tile-content {
        display: flex;
        flex-direction: column;
  
        .parent-tile-title {
          font: $h13-semibold;
          margin-bottom: 18px;
  
          @include breakpoint(small only) {
            font: $h12-semibold;
          }
        }
  
        .parent-tile-description {
          font: $h4-reg;
        }
      }
    }

    .tile-arrow {
      transition: transform 0.3s ease-in-out;
      margin-top: 26px;

      @include breakpoint(small only) {
        margin-top: 32px;
      }
    }
  }

.child-tiles-container {
  padding: 30px;
  border-radius: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 15px;
  margin-top: 15px;

  @include breakpoint(850px down) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @include breakpoint(medium only) {
    gap: 8px;
  }

  @include breakpoint(small only) {
    margin-top: 0px;
    grid-template-columns: 1fr 1fr;
    padding: 16px;
    gap: 8px;
  }

    .child-tile {
      background-color: #FFFFFF;
      width: 100%;
      border-radius: 10px;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
      padding: 25px 38px;

      @include breakpoint(1200px down) {
        padding: 24px;
      }

      @include breakpoint(small only) {
        padding: 12px;
      }

      .child-tile-category {
        color: #707070;
        font: $p11-bold;
      }

      .child-tile-title {
        font: $h13-semibold;
        color: #000000;
        min-height: 80px;

        @include breakpoint(1200px down) {
          font: $h12-semibold;
          min-height: 56px;
        }

        @include breakpoint(small only) {
          font: $h2-semibold;
          min-height: 52px;
        }
      }

      .stat {
        font: $h8-semibold;

        @include breakpoint(1200px down) {
          font: $CTAs-3x;
        }

        @include breakpoint(small only) {
          font: $h1-semibold;
        }
      }

      .child-tile-description {
        font: $h4-reg;
        color: #000000;

        @include breakpoint(1200px down) {
          font: $p11-reg;
        }
      }
    }
}
