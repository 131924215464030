@import "../../../../../node_modules/foundation-sites/scss/foundation.scss";
@import "../../toyota-style-standards.scss";

.smartpath-pricing-component {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;

    .pricing-section-title {
        font: $font32-bold;
    
        @include breakpoint (medium only){
            font: $h3-bold;
        }
    
        @include breakpoint (small only){
            padding: 0px 23px;
            font: $h12-bold;
        }
    }
    
    .pricing-section-card-container {
        display: flex;
        gap: 24px;
        overflow-x: scroll;
        flex-wrap: nowrap;
        user-select: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        scrollbar-width: none;
    
        .pricing-card {
            display: flex;
            flex-direction: column;
            flex-shrink: 0;
            width: 382px;
            border-radius: 12px;
            padding: 42px;
            background-color: #F8F8F8;
    
            @include breakpoint (medium down){
                width: 316px;
                padding: 24px;
            }
    
    
            .pricing-card-title {
                font: $h7-semibold;
                text-align: center;
    
                @include breakpoint (medium down){
                    font: $h12-semibold;
                }
            }
    
            .pricing-card-image {
                margin: 50px 40px;
            }
    
            .pricing-card-price {
                font:$h6-bold;
                text-align: center;
                margin-bottom: 20px;
    
                @include breakpoint (medium down){
                    font: $h3-bold;
                }
            }
            
            .pricing-card-description {
                font: $h4-reg;
                margin-bottom: 40px;
            }

            .pricing-card-list-section {
                display: flex;
                flex-direction: column;
                gap: 20px;

                .pricing-card-list-item {
                    display:flex;
                    gap: 15px;
                    align-items: center;
        
                    @include breakpoint (medium down){
                        gap: 10px
                    }
        
                    .pricing-card-list-icon {
                        width: 22px;
                        height: 22px;
                    };
                    
                    .pricing-card-list-text {
                        font: $p5-reg;
                    }
                }
            }
        }

        @include breakpoint(small only) {
            .pricing-card:first-child {
                margin-left: 23px;
            }
        }
    }

    // workaround for safari ignoring margin in certain scenarios
    .pricing-section-card-container::after {
        content: "";
        flex: 0 0 1px;
    }

    .pricing-section-card-container::-webkit-scrollbar {
        display: none;
    }
}
