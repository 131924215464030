@import "../../../../../node_modules/foundation-sites/scss/foundation.scss";
@import "../../toyota-style-standards.scss";

.rts-container {
    width: 100%;
    padding: 0px 55px;
    margin-top: 150px;

    @include breakpoint(medium only) {
        margin-top: 84px;
    }

    @include breakpoint(small only) {
        padding: 0px 24px;
        margin-top: 48px;
    }

    .rts-component {
        display: flex;
        flex-direction: column;
        max-width: 1440px;
        width: 100%;
        margin: 0 auto;
    
        .rts-header {
            font: $h11-bold;
            font-weight: 700;
            margin-bottom: 48px;
    
            @include breakpoint(medium only) {
                font: $h1-bold;
            }
    
            @include breakpoint(small only) {
                margin-bottom: 18px;
                font: $h3-bold;
            }
        }
    
        .rts-tile-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            gap: 20px;
    
            @include breakpoint(small only) {
                flex-direction: column;
            }
    
            .rts-step-container {
                position: relative;
                display: flex;
    
                .rts-tile-image {
                    width: 100%;
                    max-width: 464px;
                    border-radius: 10px;
    
                    @include breakpoint(medium down) {
                        max-width: none;
                    }
                }
    
                .rts-content {
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 75%;
                    padding: 42px;
                    color: #FFFFFF;

                    @include breakpoint(medium down) {
                        width: 100%;
                        padding: 24px;
                    }
    
                    &-label {
                        font: $p11-bold;
                        font-weight: 700;
    
                        @include breakpoint(medium only) {
                            font: $p7-bold;
                        }
    
                        @include breakpoint(small only) {
                            font: $p7-bold;
                        }
                    }
    
                    &-text {
                        font: $h13-reg;
    
                        @include breakpoint(medium down) {
                            font: $h3-semibold;
                        }
    
                        @include breakpoint(small only) {
                            font: $h12-semibold;
                        }
                    }
                }
            }
        }
    
        .rts-button {
            width: 260px;
            height: 72px;
            border-radius: 48px;
            background-color: #EB0A1E;
            font: $h3-semibold;
            margin: 70px auto 0px auto;
            color: #FFFFFF;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-bottom: 2px;
            cursor: pointer;

            @include breakpoint(medium only) {
                margin: 48px auto 0px auto;
            }
    
            @include breakpoint(small only) {
                margin: 32px auto 0px auto;
                width: 174px;
                height: 48px;
                font: $h12-semibold;
            }
        }
    }
}
