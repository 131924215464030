@import "../../../../../node_modules/foundation-sites/scss/foundation.scss";
@import "../../toyota-style-standards.scss";

.smartpath-marketing-assets-container {
    .marketing-assets-tile-container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 32px;
        row-gap: 48px;

        @include breakpoint(medium only) {
            grid-template-columns: 1fr 1fr;
            gap: 24px;
        }

        @include breakpoint(small only) {
            grid-template-columns: 1fr 1fr;
            column-gap: 12px;
            row-gap: 24px;
        }
    }
}