@import "../../../../../node_modules/foundation-sites/scss/foundation.scss";
@import "../../toyota-style-standards.scss";

.linkout-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $grey-area;
    width: 100%;
    height: 95px;
    font: $h7 $toyota-reg;
    color: $primary-black;
    padding: 0px 20px;

    @include breakpoint(small only) {
        font: $h4 $toyota-reg;
        height: 74px;
    }

    p {
        margin-bottom: 0px;
        text-align: center;

        .linkout {
            color: $primary-red;
            cursor: pointer;
            font: $h7 $toyota-semibold;
    
            @include breakpoint(small only) {
                font: $h4 $toyota-semibold;
            }
        }
        
        .arrow{
            vertical-align: middle;
            width: 16px;
            height: 16px;
        
            @include breakpoint(small only) {
                width: 12px;
                height: 12px;
            }
        }
    }
}