@import "../../../../../node_modules/foundation-sites/scss/foundation.scss";
@import "../../toyota-style-standards.scss";

.sticky-tabs-component {
    display: flex;
    flex-direction: column;
    gap: 64px;
    width: 100%;
    align-items: center;
    margin-bottom: 150px;
    margin-top: 100px;

    @include breakpoint(medium only) {
        gap: 48px;
        margin-bottom: 64px;
        margin-top: 84px;
    }

    @include breakpoint(small only) {
        gap: 23px;
        margin-bottom: 32px;
        align-items: flex-start;
        margin-top: 32px;
    }

    .component-headline {
        font: $font42-bold;
        width: 100%;
        max-width: 1550px;
        padding: 0px 55px;
        
        @include breakpoint(medium only) {
            font: $h1-bold;
        }

        @include breakpoint(small only) {
            font: $h3-bold;
            padding: 0px 23px;
            max-width: 300px;
        }
    }

    .sticky-tabs-container {
        width: 100%;
        max-width: 1550px;
        padding: 0px 55px;
        display: flex;
        flex-direction: row;
        gap: 48px;

        &.extend {
            padding: 0px 0px 0px 55px;
        }

        @include breakpoint(medium only) {
            gap: 42px;
        }
    
        @include breakpoint(small only) {
            flex-direction: column;
            gap: 24px;
            padding: 0px;

            &.extend {
                padding: 0px;
            }
        }
    
        .navigation-menu {
            position: sticky;
            top: 125px;
            width: 194px;
            height: min-content;
            z-index: 1;
    
            @include breakpoint (medium only) {
                width: 152px;
            }
    
            @include breakpoint (small only) {
                top: 80px;
                overflow-x: scroll;
                scrollbar-width: none;
                width: 100%;
                padding: 0px 23px;
            }
    
            .tab-container {
                display: flex;
                flex-direction: column;
                border-radius: 12px;
                background-color: #F8F8F8;
                gap: 12px;
                margin: auto;
    
                @include breakpoint (medium only) {
                    width: 152px;
                    gap: 8px;
                }
        
                @include breakpoint (small only) {
                    flex-direction: row;
                    width: min-content;
                    gap: 8px;
                }
    
                .sticky-tab {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    height: auto;
                    padding: 24px;
                    border-radius: 12px;
                    cursor: pointer;
        
                    @include breakpoint (medium only) {
                        padding: 16px;
                    }
        
                    @include breakpoint (small only) {
                        padding: 12px;
                        min-width: 90px;
                        justify-content: center;
                        width: auto;
                    }
        
                    .tab-label {
                        font: $h3-reg;
                        color: black;
        
                        @include breakpoint (medium only) {
                            font: $h12-reg;
                        }
        
                        @include breakpoint (small only) {
                            font: $p11-reg;
                            white-space: nowrap;
                        }                
                    }
        
                    .tab-arrow {
                        width: 20px;
                        height: 20px;
        
                        @include breakpoint (medium only) {
                            width: 16px;
                            height: 16px;
                        }
        
                        @include breakpoint (small only) {
                            display: none;
                        }
                    }
        
                    &.selected {
                        background-color: #EB0A1E;
        
                        .tab-label {
                            color: white;
                            font-weight: 700;
                        }
                    }
                }
            }

            @include breakpoint(small only) {
                // workaround for safari ignoring padding in certain scenarios
                .tab-container::after {
                    content: "";
                    flex: 0 0 23px;
                }
            }
        }
    
        .navigation-menu::-webkit-scrollbar {
            display: none;
        }
    
        .sticky-tabs-content {
            display: flex;
            flex-direction: column;
            row-gap: 50px;

            // Need to set a width but flex: 1 grows container to appropriate size
            width: 1px;
            flex: 1;

            &.extend {
                width: calc(100vw - 297px);
            }

            @include breakpoint(small only) {
                width: 100%;
                padding: 0px 23px;

                &.extend {
                    width: 100%;
                    padding: 0px;
                }
            }
        }
    }
}
