@import "../../../../styles/scss/toyota-style-standards.scss";
@import "../../../../../node_modules/foundation-sites/scss/foundation.scss";

.welcome-tiles-container {
  display: flex;
  flex-direction: row;
  gap: 24px;
  padding: 0px 60px;
  margin-top: 80px;

  @include breakpoint(medium only) {
    margin-top: 84px;
  }

  @include breakpoint(small only) {
    flex-direction: column;
    padding: 0px 24px;
    margin-top: 32px;
  }

  .tile-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color:#F8F8F8;
    width: 100%;
    max-width: 708px;
    gap: 10px;
    border-radius: 12px;
    padding: 48px 64px 56px 64px;
  
    @include breakpoint(medium only) {
      padding: 32px 42px;
    }
  
    @include breakpoint(small only) {
      padding: 24px;
    }
  
    .img {
      border-radius: 5px;
    }
    
    .tile-title {
      font:$h11-bold;
      margin-bottom: 42px;
  
      @include breakpoint(medium only) {
        font-size: 36px;
        margin-bottom: 32px;
      }
    
      @include breakpoint(small only) {
        font-size: 24px;
        margin-bottom: 24px;
      }
    }
  
    .tile-content {


      .info-container {
        .tile-subtitle {
          font: $h3-bold;
          margin-top: 30px;
  
          @include breakpoint(medium only) {
            margin-top: 20px;
          }
    
          @include breakpoint(small only) {
            font-size: 20px;
            margin-top: 20px;
          }
        }
        
        .tile-body {
          font: $h4-reg;
          font-weight: 400;
    
          @include breakpoint(small only) {
            font-size: 14px;
          }
        }
      }
    }

    .tile-button {
      display: inline-block;
      padding: 18px 60px 22px 60px;
      width: fit-content;
      color: white;
      font: $h3-reg;
      font-weight: 600;
      text-align: center;
      text-decoration: none;
      border-radius: 48px;
      margin-top: 24px;
      transition: background-color 0.3s;
      cursor: pointer;

      @include breakpoint(medium only) {
        margin-top: 14px;
      }

      @include breakpoint(small only) {
        font-size: 20px;
      }
    }
    
    .tile-button:hover {
      opacity: 0.8;
    }
  }
}
  