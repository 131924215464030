@import "../../../../../node_modules/foundation-sites/scss/foundation.scss";
@import "../../toyota-style-standards.scss";

.previous-release-notes-container {
    display: flex;
    flex-direction: column;
    margin-top: 150px;

    @include breakpoint(medium only) {
        margin-top: 64px;
    }

    @include breakpoint(small only) {
        margin-top: 32px;
    }

    .title-dropdown-section {
        display: flex;
        align-items: center;
        gap: 16px;
        height: 60px;
        margin-bottom: 32px;

        @include breakpoint(medium only) {
            margin-bottom: 48px;
        }

        @include breakpoint(small only) {
            margin-bottom: 16px;
        }

        .dropdown-container {
            .dropdown-control {
                min-width: 165px;
                height: 42px;

                @include breakpoint(medium only) {
                    min-width: 150px;
                    height: 40px;
                }

                @include breakpoint(small only) {
                    min-width: 120px;
                    height: 36px;
                }

                :global(.Dropdown-placeholder) {
                    color: black;
                    font: $h2-semibold;
                }

                &>div:nth-of-type(2) {
                    width: fit-content;
                    margin: 0;
                }
            }
        }

        .container-title {
            font: $h11-bold;

            @include breakpoint(medium only) {
                font: $h1-bold;
            }

            @include breakpoint(small only) {
                font: $h3-bold;
            }
        }
    }
}