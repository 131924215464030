@import "../../../../../node_modules/foundation-sites/scss/foundation.scss";
@import "../../toyota-style-standards.scss";

.release-notes-section {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    gap: 32px;

    @include breakpoint(medium only) {
        gap: 20px;
    }

    @include breakpoint(small only) {
        grid-template-columns: 1fr;
        gap: 16px;
    }
}

.button-container {
    align-self: center;
    display: flex;
    justify-content: center;
    margin: 70px 0 20px;

    @include breakpoint(medium only) {
        margin: 48px 0 20px;
    }

    @include breakpoint(small only) {
        margin: 36px 0 20px;
    }

    .rounded-button {
        background-color: $dark-black;
        color: white;
        font: $h2-semibold;
        cursor: pointer;
        border-radius: 48px;
        display: flex;
        width: 195px;
        height: 56px;
        padding: 16px 24px;
        justify-content: center;
        align-items: center;
        padding-bottom: 20px;


        @include breakpoint(medium only) {
            width: 143px;
            height: 56px;
        }

        @include breakpoint(small only) {
            width: 110px;
            height: 39px;
            font: $p11-semibold;
            white-space: nowrap;
        }
    }
}