@import "../../../../../node_modules/foundation-sites/scss/foundation.scss";
@import "../../toyota-style-standards.scss";

.pagination-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 70px;

    .arrow-buttons {
        cursor: pointer;

        @include breakpoint(small only) {
            width: 41px;
            height: 41px;
        }
    }

    .left-arrow {
        transform: rotate(180deg);
    }

    .arrow-disabled {
        cursor: default;

        &>rect {
            fill: $grey-area;
        }

        &>svg>path {
            fill: $border-grey;
        }
    }

    .page-number-container {
        display: flex;
        font: $p14-reg;
        padding: 0px 16px;

        @include breakpoint(small only) {
            font: $p11-reg;
        }
    }
}