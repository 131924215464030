@import "../../../../../node_modules/foundation-sites/scss/foundation.scss";
@import "../../toyota-style-standards.scss";

.spotlighted-release-notes-container {
    margin-top: 64px;

    @include breakpoint(small only) {
        margin-top: 32px;
    }

    .container-title {
        font: $h11-bold;
        margin-bottom: 32px;

        @include breakpoint(medium only) {
            font: $h1-bold;
            margin-bottom: 48px;
        }

        @include breakpoint(small only) {
            font: $h3-bold;
            margin-bottom: 16px;
        }
    }

    .release-notes-section {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 32px;

        @include breakpoint(medium only) {
            gap: 23px;
        }

        @include breakpoint(small only) {
            grid-template-columns: 1fr;
            gap: 16px;
        }
    }
}