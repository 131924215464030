@import "../../../../../node_modules/foundation-sites/scss/foundation.scss";
@import "../../toyota-style-standards.scss";

.marketing-assets-tile {
    width: 100%;

    .image-container {
        position: relative;

        .marketing-asset-thumbnail {
            border-radius: 5px;
        }
    
        .download-icon {
            position: absolute;
            right: 25px;
            bottom: 25px;
            cursor: pointer;

            @include breakpoint(small only) {
                width: 32px;
                height: 32px;
                right: 12px;
                bottom: 12px;
            }
        }
    
        .download-icon:hover {
            .line {
                stroke: $primary-red;
            }
        
            .arrow {
                fill: $primary-red;
            }
        }
    }

    .asset-title {
        font: $h7-reg;
        font-weight: 600;
        margin-top: 16px;

        @include breakpoint(medium down) {
            font-size: 20px;
        }
    }

    .asset-label {
        font: $h2-reg;
        font-weight: 400;

        @include breakpoint(medium down) {
            font-size: 16px;
        }
    }
}
