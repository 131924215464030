@import "../../../../../node_modules/foundation-sites/scss/foundation.scss";
@import "../../toyota-style-standards.scss";

.search-results-container {
    width: 100%;
    max-width: 1560px;
    margin: auto;
    display: flex;
    flex-direction: column;

    .header {
        display: flex;
        flex-direction: column;
        margin-top: 64px;

        @include breakpoint(medium only) {
            margin-top: 48px;
        }

        @include breakpoint(small only) {
            margin-top: 18px;
        }

        &>button {
            display: flex;
            gap: 12px;
            align-items: center;
            width: fit-content;
            cursor: pointer;

            img {
                width: 9px;
                height: 16px;
            }

            span {
                color: $primary-red;
                font: $h2-bold;
            }
        }

        &>div {
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: $dark-black;
            margin-top: 24px;
            margin-bottom: 32px;

            @include breakpoint(medium only) {
                margin-bottom: 48px;
            }

            @include breakpoint(small only) {
                flex-direction: column;
                align-items: flex-start;
                margin-top: 4px;
                margin-bottom: 16px;
            }

            div.results-for {
                span {
                    font: $h13-reg;
                }

                span:last-of-type {
                    font: $h13-bold;
                }

                @include breakpoint(medium only) {
                    span {
                        font: $h3-reg;
                    }
    
                    span:last-of-type {
                        font: $h3-bold;
                    }
                }

                @include breakpoint(small only) {
                    span {
                        font: $h12-reg;
                    }
    
                    span:last-of-type {
                        font: $h12-bold;
                    }
                }
            }

            div.results-count {
                span {
                    font: $p5-bold;
                }

                span:last-of-type {
                    font: $p5-reg;
                }
            }
        }
    }

    .no-results {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin: 160px auto 150px auto;

        span {
            color: $dark-black;
            font: $h13-semibold;
        }

        @include breakpoint(small only) {
            margin: 124px auto 124px auto;
            span {
                font: $h12-semibold;
                width: 210px;
                text-align: center;
            }
        }
    }
}