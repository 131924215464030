@import "../../../../../node_modules/foundation-sites/scss/foundation.scss";
@import "../../toyota-style-standards.scss";

.email-form-container-wrapper {
    padding: 0 55px;

    @include breakpoint(medium down) {
        padding: unset;
    }

    .email-form-container {
        --font-color: #2C611A;
        --scroll-thumb-color: #BBB9B9;
        --scroll-thumb-hover-color: #a0a0a0;
        --background-color: #F8F8F8;
        --background-color-gray-green: #d0e9df;
        --box-font-color: #016377;

        display: flex;
        width: 100%;
        max-width: 1440px;
        border-radius: 12px;
        column-gap: 20px;
        justify-content: space-between;
        margin: 0px auto;
        background: linear-gradient(262.97deg, #11BA70 1.32%, #017982 95.36%);
        padding: 72px 80px 71px 96px;

        @include breakpoint(medium only) {
            width: auto;
            flex-direction: column;
            gap: 65px;
            padding: 64px 64px 94px 64px;
            margin: 0px 50px;
        }

        @include breakpoint(small only) {
            width: auto;
            flex-direction: column;
            gap: 32px;
            padding: 32px 36px;
            margin: 0px 24px;
        }

        .products-container {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 64px;

            @include breakpoint(medium down) {
                gap: 48px;
            }

            .products-header {
                display: flex;
                flex-direction: column;
                gap: 24px;
                max-width: 452px;

                h2 {
                    font: $h11-bold;
                    color: $primary-white;
                    width: 100%;
                    margin: 0;

                    @include breakpoint(medium only) {
                        font: $h1-bold;
                    }

                    @include breakpoint(small only) {
                        font: $font32-bold;
                    }
                }

                span {
                    font: $h12-reg;
                    color: $primary-white;
                }
            }

            .products-box {
                display: flex;
                flex-direction: column;
                gap: 18px;

                .selection-subtitle {
                    font: $h12-reg;
                    color: $primary-white;
                }

                .selectable-boxes {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    gap: 16px;
                    width: 100%;
                    max-width: 218px + 218px + 16px;

                    @include breakpoint(medium down) {
                        grid-template-columns: repeat(4, 1fr);
                        max-width: 800px;
                        gap: 16px;
                    }

                    @include breakpoint(850px down) {
                        grid-template-columns: repeat(3, 1fr);
                        gap: 16px;
                    }

                    @include breakpoint(small down) {
                        grid-template-columns: repeat(2, 1fr);
                        gap: 12px;
                    }

                    .selectable-box {
                        width: 100%;
                        height: 160px;
                        border-radius: 12px;
                        border: 1px solid $primary-white;
                        background-color: transparent;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        gap: 10px;
                        max-width: 218px;

                        @include breakpoint(medium down) {
                            width: 100%;
                            max-width: 190px;
                            height: 160px;
                        }

                        @include breakpoint(850px down) {
                            width: 100%;
                            max-width: 200px;
                            max-height: 128px;
                        }

                        @include breakpoint(small down) {
                            width: 100%;
                            max-width: 240px;
                            max-height: 128px;
                        }

                        .box-label {
                            font: $h6-semibold;
                            color: $primary-white;
                            overflow: hidden;
                            text-overflow: ellipsis;

                            @include breakpoint(small only) {
                                font: $h3-semibold;
                            }
                        }

                        img {
                            display: none;
                            padding-top: 4px;
                        }

                        &.selected {
                            background-color: $primary-white;
                            overflow: hidden;

                            .box-label {
                                color: var(--box-font-color);
                            }

                            img {
                                display: initial;
                            }
                        }
                    }
                }
            }
        }

        .message-container {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 32px;

            section {
                display: flex;
                flex-direction: column;
                gap: 24px;

                .header-section {
                    display: flex;
                    flex-direction: column;
                    gap: 8px;

                    .title-format {
                        font: $h13-semibold;
                        color: $primary-white;
                        margin: 0;
                        line-height: 31px;
                    }

                    .description-format {
                        font: $p14-reg;
                        color: $primary-white;
                        line-height: 20px;

                        @include breakpoint(small only) {
                            font: $h4-reg;
                        }
                    }
                }

                .form-section {
                    display: flex;
                    flex-direction: column;
                    gap: 16px;
                    width: 100%;
                    margin: 0;
                    padding: 0;

                    .input-row {
                        display: flex;
                        gap: 16px;
                        width: 100%;

                        @include breakpoint(500px down) {
                            flex-direction: column;
                        }
                    }

                    input {
                        width: 100%;
                        height: 55px;
                        background-color: var(--background-color-gray-green);
                        margin: 0;
                        padding: 0;
                        padding-left: 20px;
                        border-radius: 5px;
                        color: var(--font-color);
                        font: $h4-reg;
                        line-height: 18px;

                        &::placeholder {
                            color: var(--font-color);
                            font: $h4-reg;
                            line-height: 18px;
                        }
                    }

                    textarea {
                        width: 100%;
                        height: 122px;
                        border-radius: 5px;
                        padding: 10px;
                        margin: 0;
                        background-color: var(--background-color-gray-green);
                        resize: none;
                        overflow-y: auto;
                        font: $h4-reg;
                        text-align: left;
                        color: var(--font-color);

                        @include breakpoint(small only) {
                            height: 166px;
                        }

                        &::-webkit-scrollbar {
                            width: 10px;
                        }

                        &::-webkit-scrollbar-track {
                            background: var(--background-color-gray-green);
                        }

                        &::-webkit-scrollbar-thumb {
                            background: var(--scroll-thumb-color);
                            border: 4px solid var(--background-color-gray-green);
                            border-radius: 10px;
                        }

                        &::-webkit-scrollbar-thumb:hover {
                            background: var(--scroll-thumb-hover-color);
                        }
                    }
                }
            }

            .button-section {
                padding-top: 20px;

                @include breakpoint(medium down) {
                    padding-top: unset;
                }

                display: flex;
                justify-content: space-between;
                align-items: center;

                @include breakpoint(small only) {
                    flex-direction: column-reverse;
                    gap: 32px;
                    margin-top: -8px;
                }

                button {
                    border-radius: 48px;
                    background-color: $primary-white;
                    color: var(--font-color);
                    width: 137px;
                    height: 50px;
                    font: $h2-reg;
                    line-height: 1;

                    &:disabled {
                        opacity: 50%;
                    }
                }

                span {
                    font: $h4-reg;
                    color: $primary-white;

                    @include breakpoint(small only) {
                        align-self: flex-end;
                    }
                }
            }
        }
    }
}

.submission-container-wrapper {
    display: flex;
    align-items: center;
    max-width: 1550px;
    width: 100%;
    margin: 0 auto;
    position: relative;

    .submission-container {
        border-radius: 12px;
        width: 100%;
        display: flex;
        align-items: center;
        margin: 0 55px;
        position: relative;
        background-size: cover;
        height: auto;
        overflow: hidden;

        @include breakpoint(medium down) {
            height: 687px;
            height: auto;
        }

        @include breakpoint(small down) {
            height: 514px;
            margin: 0 23px;
        }

        img{
            height: auto;
            width: 100%;
            object-fit: cover;
        }

        .submission-text-container {
            color: #F8F8F8;
            display: flex;
            flex-direction: column;
            row-gap: 24px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 11%;
            width: 35%;
            height: auto;

            @include breakpoint(medium down) {
                padding: 64px 64px 0;
                top: 0;
                left: 50%;
                width: 100%;
                transform: translateX(-50%);
            }

            @include breakpoint(small down) {
                row-gap: 16px;
                padding: 32px 36px 0px 36px;
                top: 3%;
                width: 100%;
                transform: translateX(-50%);
            }

            .submission-title {
                font: $h11-bold;
                width: 100%;

                @include breakpoint(medium down) {
                    font: $h1-bold;
                }

                @include breakpoint(small down) {
                    font: $font32-bold;
                }
            }

            .submission-description {
                font: $h12-reg;
                width: 100%;
            }

            .submission-reset-button-wrapper {
                @include breakpoint(small down) {
                    display: flex;
                    justify-content: center;
                    width: 100%;
                }

                .submission-reset-button {
                    cursor: pointer;
                    text-align: center;
                    font: $p14-reg;
                    border-radius: 48px;
                    background-color: #FFFFFF;
                    color: #2C611A;
                    width: 137px;
                    height: 50px;
                    line-height: 1;

                    @include breakpoint(medium down) {
                        margin-top: 24px;
                    }
                }
            }
        }
    }
}