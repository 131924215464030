@import "../../../../../node_modules/foundation-sites/scss/foundation.scss";
@import "../../toyota-style-standards.scss";

.sales-tool-carousel-container {
    display: flex;
    flex-direction: column;
    row-gap: 24px;

    .sales-tool-carousel-header-container {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .sales-tool-carousel-title {
            font: $h13-bold;

            @include breakpoint(medium down) {
                font: $h3-bold;
            }

            @include breakpoint(small down) {
                font: $h12-bold;
            }
        }

        .sales-tool-carousel-buttons {
            display: flex;
            column-gap: 12px;

            &.disabled {
                display: none;
            }

            img {
                filter: grayscale(0) brightness(0) contrast(100%);
                width: 32px;
                height: 32px;
                -webkit-user-drag: none;
                user-select: none;

                &:hover {
                    cursor: pointer;
                }
            }

            .sales-tool-next-button {
                transform: rotate(180deg);

                &.disabled {
                    opacity: 0.25;
                    cursor: not-allowed;
                }
            }

            .sales-tool-prev-button {
                &.disabled {
                    opacity: 0.25;
                    cursor: not-allowed;
                }
            }
        }
    }

    .sales-tool-carousel {
        width: 100%;
        position: relative;

        .slick-slider {
            position: relative;

            .slick-slide {
                height: auto;
                padding: 0px 12px 0px 0px;
            }

            .slick-track {
                height: auto;
                margin-left: unset;
            }
        }
    }
}