@import "../../../../../node_modules/foundation-sites/scss/foundation.scss";
@import "../../toyota-style-standards.scss";

.smartpath-header {
    position: relative;
    color: $primary-white;

    .header-image {
        width: 100%;
    }

    .content-container {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 12.5%;
        width: 32%;
        height: auto;
        line-height: 0.8;

        @include breakpoint(medium only) {
            top: 6.5%;
            left: 50%;
            width: 57%;
            transform: translateX(-50%);
        }

        @include breakpoint(small only) {
            top: 7.3%;
            left: 50%;
            width: 89%;
            transform: translateX(-50%);
        }

        .headline-text {
            font: $h10-bold;
            font-size: calc(3.5vw - 4px);
            margin: 0px auto 1.25vw auto;
            line-height: 1;

            @include breakpoint(medium only) {
                text-align: center;
                font-size: calc(5vw + 6px);
                margin-bottom: 2vw;
            }

            @include breakpoint(small only) {
                text-align: center;
                font-size: calc(8vw + 1px);
                margin-bottom: 5.6vw;
            }
        }

        .description-text {
            font: $h12-reg;
            font-size: calc(1.05vw - 1px);
            max-width: 78%;
            margin: 0px auto 2.5vw 0px;

            @include breakpoint(medium only) {
                font-size: calc(1.7vw + 0.5px);
                text-align: center;
                margin: 0px auto 4vw auto;
                max-width: 88%;
            }

            @include breakpoint(small only) {
                font-size: calc(3vw + 1px);
                text-align: center;
                margin: 0px auto 11.25vw auto;
                max-width: 82%;
            }
        }

        .video-thumbnail-container {
            position: relative;
            max-width: 78%;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 66%, #2A2A2A 100%);
            border-radius: 8px;

            @include breakpoint(medium only) {
                max-width: 100%;
            }

            @include breakpoint(small only) {
                max-width: 100%;
            }

            .video-thumbnail-image {
                position: relative;
                border-radius: 8px;
                z-index: -1;
            }

            .video-tumbnail-info-container {
                position: absolute;
                display: flex;
                align-items: center;
                gap: 16px;
                bottom: 7.5%;
                left: 4.5%;

                .play-button-icon:hover {
                    cursor: pointer;
                    .circle {
                        opacity: 0.8;
                    }
                }

                .play-button-label {
                    font: $h12-reg;
                    font-weight: 600;
                }
            }
        }

        .thumbnail-skeleton {
            display: flex;
            align-items: center;
            justify-content: center;
            max-width: 78%;
            aspect-ratio: 16/9;
            border-radius: 8px;
            background-color: #ebebeb80;

            @include breakpoint(medium down) {
                max-width: 100%;
            }
        }

        &.expanded-tablet {
            @include breakpoint(medium only) {
                width: 79%;

                .headline-text {
                    max-width: 65%;
                }
    
                .description-text {
                    max-width: 65%;
                }
            }
        }
    }
}