@import "../../../../../node_modules/foundation-sites/scss/foundation.scss";
@import "../../toyota-style-standards.scss";

.training-content-carousel-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 32px;
    @include breakpoint(small down) {
        row-gap: 24px;
    }

    .training-content-carousel-header-container {
        display: flex;
        column-gap: 16px;
        align-items: center;
        width: 100%;
        font: $h11-bold;
        padding-left: 240px;

        @include breakpoint(medium down) {
            font: $h1-bold;
            padding-left: 60px;
        }

        @include breakpoint(small down) {
            font: $h3-bold;
            padding-left: 24px;
        }

        .training-content-dropdown {
            width: 165px;
            height: 42px;

            @include breakpoint(medium only) {
                width: 150px;
                height: 40px;
            }

            @include breakpoint(small only) {
                width: 120px;
                height: 36px;
            }

            .Dropdown-root {
                width: 100%;

                .Dropdown-control {
                    .Dropdown-placeholder {
                        @include breakpoint($xl-mobile down) {
                            font-size: 15px;
                        }
                    }
                }

                .Dropdown-menu {
                    min-width: min-content;
                    font: $h2-semibold;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    word-break: break-word;
                    white-space: nowrap;
                    z-index: 3;

                    .Dropdown-option {
                        img {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    .training-content-carousel {
        display: flex;
        flex-direction: row;
        overflow-x: scroll;
        scrollbar-width: none;
        gap: 32px;

        @include breakpoint(medium down) {
            gap: 24px;
        }

        .resources-tile:first-child {
            margin-left: 240px;

            @include breakpoint(medium only) {
                margin-left: 60px;
            }

            @include breakpoint(small only) {
                margin-left: 24px;
            }
        }

        .resources-tile:last-child {
            margin-right: 240px;

            @include breakpoint(medium only) {
                margin-right: 60px;
            }

            @include breakpoint(small only) {
                margin-right: 24px;
            }
        }
    }

    .training-content-carousel::-webkit-scrollbar {
        display: none;
    }

    .dots-indicator {
        display: flex;
        flex-direction: row;
        gap: 12px;
        margin: auto;

        .dot {
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background-color: #D9D9D9;
            cursor: pointer;

            &.selected {
                background-color: black;
            }
        }
    }
}